@font-face {
  font-family: 'SF UI Text Rg';
  src: url('../fonts/SFUIText-Regular.eot');
  src: url('../fonts/SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
       url('../fonts/SFUIText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text Lt';
  src: url('../fonts/SFUIText-Light.eot');
  src: url('../fonts/SFUIText-Light.eot?#iefix') format('embedded-opentype'),
       url('../fonts/SFUIText-Light.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

%SFUITextLight {
  font-family: 'SF UI Text Lt', sans-serif;
  font-weight: 300;
  font-style: normal;
}

%SFUITextRegular {
  font-family: 'SF UI Text Rg', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes blink {
  from, to { color: transparent; }
  50% { color: inherit; }
}

@keyframes rotate-box {
  // 0% { transform: rotateX(45deg) rotateY(0deg); }
  0% { transform: rotateX(45deg) rotateY(0deg); }
  50% { transform: rotateX(225deg) rotateY(180deg); }
  100% { transform: rotateX(405deg) rotateY(360deg); }
}

@keyframes click {
  30% { transform: translate(0, 0) scale(100%) rotate(0); }
  40% { transform: translate(-13px, 0px) scale(120%) rotate(-20deg); }
  50% { transform: translate(0, 0) scale(100%) rotate(0); }
}
@import "_reset";
@import "_utility";

$background: #ddd;
$header-color: #2f422f;
$text-color: #0c6f1d;
$link-color: #239dc3;

html, body {
  min-height: 100%;
}

body {
  position: relative;
  background-color: $background;
  overflow-x: hidden;
  @extend %SFUITextRegular;
  font-size: 16px;
  line-height: 1.1;
}

h2 {
  font-size: 3.6em;
  font-weight: 600;
  margin-bottom: 20px;
}

a {
  color: $text-color;
  transition: color 200ms linear;
  &:hover {
    color: $link-color;
  }
}

.container {
  padding: 50px 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  padding-bottom: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  color: $header-color;
  margin-bottom: 40px;

  .header-content {
    p {
      font-family: monospace, monospace;
      font-size: 1.3em;
      margin-bottom: 15px;
      padding-left: 10px;
      position: relative;
      i {
        font-size: 14px;
      }
      button {
        cursor: pointer;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        outline: inherit;
        .fa-chevron-down {
          margin-right: -3px;
        }
      }
      .fa-mouse-pointer {
        animation: click 7s infinite cubic-bezier(0.075, 0.82, 0.165, 1);
      }
    }
    #su-list {
      display: none;
      list-style: none;
      padding-left: 40px;
      font-size: .9em;
      li {
        margin-bottom: 5px;
        i {
          font-size: .7em;
          padding-right: 5px;
        }
      }
    }
    .cursor {
      position: absolute;
      bottom: 0px;
      // right: -15px;
      font-size: 1.2em;
      animation: 1.2s blink step-end infinite;
    }
  }
  .header-cube {
    display: none;
    perspective: 800px;
    margin: 0 20px;
    $box-width: 170px;

    .home-box {
      margin: 10% auto;
      width: $box-width;
      height: $box-width;
      transform-style:preserve-3d;
      position:relative;
      transform: rotateX(45deg);
      transform-origin: 50% 50% (-$box-width/2);
      animation: rotate-box 8s infinite linear;

      div{
          position:absolute;
          width:$box-width;
          height:$box-width;
          background-color:#4c4c4c;
          transform-style:preserve-3d;
          backface-visibility:hidden;
      }
      // .boxCorner1  = Front
      // .boxCorner1::before = Left
      // .boxCorner1::after = Top
      // .boxCorner2 = Back
      // .boxCorner2::before = Right
      // .boxCorner2::after = Bottom        
      .boxCorner1 {
        background-image: url('../images/side1.png');
        background-size: cover;
      }
      .boxCorner2 {
        transform: rotateX(180deg) translateZ($box-width);
        background-image: url('../images/side5.gif');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        background-color: #c1c1c1;
      }
      .boxCorner1::before, .boxCorner1::after, .boxCorner2::before, .boxCorner2::after {
        position:absolute;
        content:'';
        display:block;
        width:100%;
        height:100%;
        transform-style:preserve-3d;
        background-color:#444;
        background-size: cover; 
      }
      .boxCorner1::before {
        transform: rotateY(90deg) rotateX(180deg);
        transform-origin: 0 50%;
        background-image: url('../images/side6.gif');
        background-size: contain;
        background-color: #5d534d;
        background-repeat: no-repeat;
        background-position: center
      }
      .boxCorner2::before {
        transform-origin: 100% 50%;
        transform: rotateY(-90deg);
        background-image: url('../images/side2.png');
      }
      .boxCorner1::after, .boxCorner2::after {
        transform: rotateX(-90deg);
        transform-origin: 50% 0;
      }    
      .boxCorner1::after{
        background-image: url('../images/computah.jpg');
      }
      .boxCorner2::after{
        background-image: url('../images/website.gif');
      }
    }
  }
}

.experience {
  .xp-list {
    flex: 1;
    margin-bottom: 35px;
    margin-right: 10px;
    @extend %SFUITextLight;
    color: $text-color;
    a {
      text-decoration: none;
    }
    .section-header {
      margin-bottom: 12px;
      color: $header-color;
      h3 {
        font-size: 1.1em;
        font-weight: 300;
        letter-spacing: .5px;
        margin-bottom: 3px;
        a {
          font-size: 12px;
        }
      }
      p {
        font-size: 13px;
      }
    }
    ul {
      list-style: none;
      li {
        padding-left: 15px;
        cursor: default;
        display: flex; // handles white space between elements
        > a, > span {
          // display: inline-block;
          padding: 5px 0;
        }
        .strikethrough {
          text-decoration: line-through;
        }
        .item-subtitle {
          font-size: 11px;
          line-height: 20px;
          padding-left: 5px;
        }
        i {
          padding: 1px 3px 1px 2px;
          margin-right: 3px;
          font-size: 11px;
          &.fa-mobile {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.footer {
  font-size: .8em;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 10px 10px;
}

.lb-nav {
  a.lb-next, a.lb-prev {
    opacity: .3;
  }
}

@media screen and (min-width: 575px) {
  .experience {
    display: flex;
    justify-content: space-between;
  }
  .footer {
    left: auto;
    right: 0;
  }
}

@media screen and (min-width: 700px) {
  body {
    font-size: 18px;
    background-image: radial-gradient(circle at 85% 30%,#fff, #{$background} 30%);
  }
  .container {
    padding-top: 90px;
  }
  .header {
    margin-bottom: 70px;
    .header-content {
      p {
        padding-left: 40px;
      }
      #su-list {
        padding-left: 70px;
      }
    }
    .header-cube {
      display: block;
    }
  }
}

@media print {
  body {
    background: white;
  }
  .container {
    padding-top: 20px;
  }
  .cursor {
    display: none;
  }
  .header, .experience .xp-list {
    margin-bottom: 20px;
  }
  .footer {
    position: relative;
    padding: 0;
  }
}
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0-modified | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type=search] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

/**
 * Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  max-width: 100%;
}

/**
 * Prevent modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address styling not present in IE 7/8/9, Firefox 3, and Safari 4.
 * Known issue: no IE 6 support.
 */
[hidden] {
  display: none;
}

/**
 * 1. Correct text resizing oddly in IE 6/7 when body `font-size` is set using
 *    `em` units.
 * 2. Prevent iOS text size adjust after orientation change, without disabling
 *    user zoom.
 */
html {
  font-size: 100%;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
  -ms-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Address `outline` inconsistency between Chrome and other browsers.
 */
/**
 * Improve readability when focused and also mouse hovered in all browsers.
 */
/**
 * 1. Remove border when inside `a` element in IE 6/7/8/9 and Firefox 3.
 * 2. Improve image quality when scaled in IE 7.
 */
img {
  border: 0;
  /* 1 */
  -ms-interpolation-mode: bicubic;
  /* 2 */
}

/**
 * Address margin not present in IE 6/7/8/9, Safari 5, and Opera 11.
 */
figure {
  margin: 0;
}

/**
 * Correct margin displayed oddly in IE 6/7.
 */
form {
  margin: 0;
}

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

/**
 * 1. Correct color not being inherited in IE 6/7/8/9.
 * 2. Correct text not wrapping in Firefox 3.
 * 3. Correct alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  /* 1 */
  padding: 0;
  white-space: normal;
  /* 2 */
  *margin-left: -7px;
  /* 3 */
}

/**
 * 1. Correct font size not being inherited in all browsers.
 * 2. Address margins set differently in IE 6/7, Firefox 3+, Safari 5,
 *    and Chrome.
 * 3. Improve appearance and consistency in all browsers.
 */
button,
input,
select,
textarea {
  font-size: 100%;
  /* 1 */
  margin: 0;
  /* 2 */
  vertical-align: baseline;
  /* 3 */
  *vertical-align: middle;
  /* 3 */
}

/**
 * Address Firefox 3+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
button,
input {
  line-height: normal;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Chrome, Safari 5+, and IE 6+.
 * Correct `select` style inheritance in Firefox 4+ and Opera.
 */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *    and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *    `input` and others.
 * 4. Remove inner spacing in IE 7 without affecting normal text inputs.
 *    Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * 1. Address box sizing set to content-box in IE 8/9.
 * 2. Remove excess padding in IE 8/9.
 * 3. Remove excess padding in IE 7.
 *    Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
  *height: 13px;
  /* 3 */
  *width: 13px;
  /* 3 */
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari 5 and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari 5 and Chrome
 *    (include `-moz` to future-proof).
 */
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  /* 2 */
  box-sizing: content-box;
}

/**
 * Remove inner padding and search cancel button in Safari 5 and Chrome
 * on OS X.
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Remove inner padding and border in Firefox 3+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * 1. Remove default vertical scrollbar in IE 6/7/8/9.
 * 2. Improve readability and alignment in all browsers.
 */
textarea {
  overflow: auto;
  /* 1 */
  vertical-align: top;
  /* 2 */
}

/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0;
}

html,
button,
input,
select,
textarea {
  color: #222;
}

::-moz-selection {
  background: #b3d4fc;
  text-shadow: none;
}

::selection {
  background: #b3d4fc;
  text-shadow: none;
}

img {
  vertical-align: middle;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

textarea {
  resize: vertical;
}

.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0;
}

@font-face {
  font-family: 'SF UI Text Rg';
  src: url("../fonts/SFUIText-Regular.eot");
  src: url("../fonts/SFUIText-Regular.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIText-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF UI Text Lt';
  src: url("../fonts/SFUIText-Light.eot");
  src: url("../fonts/SFUIText-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/SFUIText-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

.experience .xp-list {
  font-family: 'SF UI Text Lt', sans-serif;
  font-weight: 300;
  font-style: normal;
}

body {
  font-family: 'SF UI Text Rg', sans-serif;
  font-weight: 400;
  font-style: normal;
}

@keyframes blink {
  from, to {
    color: transparent;
  }
  50% {
    color: inherit;
  }
}

@keyframes rotate-box {
  0% {
    transform: rotateX(45deg) rotateY(0deg);
  }
  50% {
    transform: rotateX(225deg) rotateY(180deg);
  }
  100% {
    transform: rotateX(405deg) rotateY(360deg);
  }
}

@keyframes click {
  30% {
    transform: translate(0, 0) scale(100%) rotate(0);
  }
  40% {
    transform: translate(-13px, 0px) scale(120%) rotate(-20deg);
  }
  50% {
    transform: translate(0, 0) scale(100%) rotate(0);
  }
}

html, body {
  min-height: 100%;
}

body {
  position: relative;
  background-color: #ddd;
  overflow-x: hidden;
  font-size: 16px;
  line-height: 1.1;
}

h2 {
  font-size: 3.6em;
  font-weight: 600;
  margin-bottom: 20px;
}

a {
  color: #0c6f1d;
  transition: color 200ms linear;
}

a:hover {
  color: #239dc3;
}

.container {
  padding: 50px 20px 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  position: relative;
  padding-bottom: 30px;
}

.header {
  display: flex;
  justify-content: space-between;
  color: #2f422f;
  margin-bottom: 40px;
}

.header .header-content p {
  font-family: monospace, monospace;
  font-size: 1.3em;
  margin-bottom: 15px;
  padding-left: 10px;
  position: relative;
}

.header .header-content p i {
  font-size: 14px;
}

.header .header-content p button {
  cursor: pointer;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  outline: inherit;
}

.header .header-content p button .fa-chevron-down {
  margin-right: -3px;
}

.header .header-content p .fa-mouse-pointer {
  animation: click 7s infinite cubic-bezier(0.075, 0.82, 0.165, 1);
}

.header .header-content #su-list {
  display: none;
  list-style: none;
  padding-left: 40px;
  font-size: .9em;
}

.header .header-content #su-list li {
  margin-bottom: 5px;
}

.header .header-content #su-list li i {
  font-size: .7em;
  padding-right: 5px;
}

.header .header-content .cursor {
  position: absolute;
  bottom: 0px;
  font-size: 1.2em;
  animation: 1.2s blink step-end infinite;
}

.header .header-cube {
  display: none;
  perspective: 800px;
  margin: 0 20px;
}

.header .header-cube .home-box {
  margin: 10% auto;
  width: 170px;
  height: 170px;
  transform-style: preserve-3d;
  position: relative;
  transform: rotateX(45deg);
  transform-origin: 50% 50% -85px;
  animation: rotate-box 8s infinite linear;
}

.header .header-cube .home-box div {
  position: absolute;
  width: 170px;
  height: 170px;
  background-color: #4c4c4c;
  transform-style: preserve-3d;
  backface-visibility: hidden;
}

.header .header-cube .home-box .boxCorner1 {
  background-image: url("../images/side1.png");
  background-size: cover;
}

.header .header-cube .home-box .boxCorner2 {
  transform: rotateX(180deg) translateZ(170px);
  background-image: url("../images/side5.gif");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #c1c1c1;
}

.header .header-cube .home-box .boxCorner1::before, .header .header-cube .home-box .boxCorner1::after, .header .header-cube .home-box .boxCorner2::before, .header .header-cube .home-box .boxCorner2::after {
  position: absolute;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  background-color: #444;
  background-size: cover;
}

.header .header-cube .home-box .boxCorner1::before {
  transform: rotateY(90deg) rotateX(180deg);
  transform-origin: 0 50%;
  background-image: url("../images/side6.gif");
  background-size: contain;
  background-color: #5d534d;
  background-repeat: no-repeat;
  background-position: center;
}

.header .header-cube .home-box .boxCorner2::before {
  transform-origin: 100% 50%;
  transform: rotateY(-90deg);
  background-image: url("../images/side2.png");
}

.header .header-cube .home-box .boxCorner1::after, .header .header-cube .home-box .boxCorner2::after {
  transform: rotateX(-90deg);
  transform-origin: 50% 0;
}

.header .header-cube .home-box .boxCorner1::after {
  background-image: url("../images/computah.jpg");
}

.header .header-cube .home-box .boxCorner2::after {
  background-image: url("../images/website.gif");
}

.experience .xp-list {
  flex: 1;
  margin-bottom: 35px;
  margin-right: 10px;
  color: #0c6f1d;
}

.experience .xp-list a {
  text-decoration: none;
}

.experience .xp-list .section-header {
  margin-bottom: 12px;
  color: #2f422f;
}

.experience .xp-list .section-header h3 {
  font-size: 1.1em;
  font-weight: 300;
  letter-spacing: .5px;
  margin-bottom: 3px;
}

.experience .xp-list .section-header h3 a {
  font-size: 12px;
}

.experience .xp-list .section-header p {
  font-size: 13px;
}

.experience .xp-list ul {
  list-style: none;
}

.experience .xp-list ul li {
  padding-left: 15px;
  cursor: default;
  display: flex;
}

.experience .xp-list ul li > a, .experience .xp-list ul li > span {
  padding: 5px 0;
}

.experience .xp-list ul li .strikethrough {
  text-decoration: line-through;
}

.experience .xp-list ul li .item-subtitle {
  font-size: 11px;
  line-height: 20px;
  padding-left: 5px;
}

.experience .xp-list ul li i {
  padding: 1px 3px 1px 2px;
  margin-right: 3px;
  font-size: 11px;
}

.experience .xp-list ul li i.fa-mobile {
  font-size: 14px;
}

.footer {
  font-size: .8em;
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 0 10px 10px;
}

.lb-nav a.lb-next, .lb-nav a.lb-prev {
  opacity: .3;
}

@media screen and (min-width: 575px) {
  .experience {
    display: flex;
    justify-content: space-between;
  }
  .footer {
    left: auto;
    right: 0;
  }
}

@media screen and (min-width: 700px) {
  body {
    font-size: 18px;
    background-image: radial-gradient(circle at 85% 30%, #fff, #ddd 30%);
  }
  .container {
    padding-top: 90px;
  }
  .header {
    margin-bottom: 70px;
  }
  .header .header-content p {
    padding-left: 40px;
  }
  .header .header-content #su-list {
    padding-left: 70px;
  }
  .header .header-cube {
    display: block;
  }
}

@media print {
  body {
    background: white;
  }
  .container {
    padding-top: 20px;
  }
  .cursor {
    display: none;
  }
  .header, .experience .xp-list {
    margin-bottom: 20px;
  }
  .footer {
    position: relative;
    padding: 0;
  }
}
